import React from 'react';

import * as amplitude from '@amplitude/analytics-browser';
import mixpanel from 'mixpanel-browser';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { Button } from '@/components/button/Button';
import { Col } from '@/components/layout/Flex';
import { LoadingScreen } from '@/components/loading/LoadingScreen';
import { Typo } from '@/components/typography/Text';
import { useKeycloak } from '@/keycloak';
import { Queries } from '@/queries/Queries';
import { PatientRouter } from '@/router/PatientRouter';
import { PractitionerRouter } from '@/router/PractitionerRouter';

export type RouterProps = {
  // Empty
};

export const Router: React.FC<RouterProps> = ({}) => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes: React.FC = () => {
  const keycloak = useKeycloak();
  const me = Queries.user.useMe({});
  const posthog = usePostHog();
  const { t } = useTranslation();

  if (me.data) {
    posthog?.identify(me.data?.id, {
      email: me.data.email,
    });
    amplitude.setUserId(me.data?.id);
    const identify = new amplitude.Identify();
    identify.set('email', me.data.email);

    mixpanel.people.set({
      $email: me.data?.email,
    });
  }

  switch (me.status) {
    case 'pending':
      return <LoadingScreen />;
    case 'success': {
      switch (me.data.userType) {
        case 'practitioner':
          return <PractitionerRouter />;
        case 'patient':
          return <PatientRouter />;
        case 'admin':
          return (
            <Col>
              <Typo type="title">{t('admin.title')}</Typo>
              <Button
                buttonType="secondary"
                onClick={() =>
                  keycloak.logout({
                    redirectUri: `${import.meta.env.VITE_APP_BASE_URL}`,
                  })
                }
              >
                {t('admin.logout')}
              </Button>
            </Col>
          );
      }
    }
    case 'error': {
      if (me.error.statusCode === 401) {
        keycloak.logout();
      }
      return <LoadingScreen />;
    }
  }
};
