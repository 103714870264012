import React from 'react';

import * as amplitude from '@amplitude/analytics-browser';
import { injectGlobal } from '@emotion/css';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { frFR } from '@mui/x-date-pickers/locales';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import mixpanel from 'mixpanel-browser';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.min.css';

import { CustomToastContainer } from '@/components/toast/Toast';
import { i18nInstance } from '@/i18n.ts';
import { KeycloakAuth } from '@/keycloak';
import { queryClient } from '@/queries/Queries';
import { Router } from '@/router/Router';
import { lightTheme } from '@/theme/LightTheme';
import '@/utils/strings';

amplitude.init('994e3222c2b71201928efbf907d12da0', {
  autocapture: true,
  serverZone: 'EU',
});
mixpanel.init('d7e94323dfec63b01b3ad69e24192e69', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  record_sessions_percent: 100,
});
const container = document.getElementById('root');

createRoot(container as HTMLElement).render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        localeText={
          frFR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <CssBaseline />
        <I18nextProvider i18n={i18nInstance}>
          <PostHogProvider
            apiKey={'phc_GPFpM2rVdF5tPT0OfqjbD1pGHalzyGZbMaBZwQ2VYj3'}
            options={{
              api_host: 'https://eu.i.posthog.com',
            }}
          >
            <KeycloakAuth>
              <React.StrictMode>
                <CustomToastContainer />
                <QueryClientProvider client={queryClient}>
                  <Router />
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    buttonPosition="top-left"
                  />
                </QueryClientProvider>
              </React.StrictMode>
            </KeycloakAuth>
          </PostHogProvider>
        </I18nextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);

injectGlobal`
    input, a, fieldset, p, ul { all: unset; }
    
    button { all: unset; cursor: pointer; }
    
    html, body, .root {
        width: 100%;
        height: 100%;
    }
`;
